<template>
  <UBanner>
    <article class="box">
      <h1 class="page-title">
        {{ librarySettings.title }}
      </h1>
      <VCard class="library__card">
        <VBreadcrumbs
          :items="breadCrumbs"
          divider=">"
        />
        <VCol
          cols="12"
          lg="12"
        >
          <VTextField
            v-model="search"
            append-icon="search"
            :label="$t('search.one')"
            :placeholder="$t('$app.library_searching_by_author_title_year_keywords') + ':'"
            clearable
            hide-details
          />
        </VCol>
        <VContainer class="library__buttons-container">
          <VBtn
            id="allBooks"
            :outlined="libraryTreeViewShow"
            color="primary"
            class="library-catalog__button"
            @click="libraryTreeViewShow = !libraryTreeViewShow"
          >
            <VIcon> {{ libraryTreeViewShow ? 'arrow_drop_down' : 'arrow_drop_up' }} </VIcon>
            {{ $t('$app.library_catalog') }}
          </VBtn>
        </VContainer>
        <VRow class="library__row-container">
          <VCol
            :class="{ 'library__tree-view': libraryTreeViewShow }"
            lg="3"
            xs="12"
          >
            <LibraryTreeView />
          </VCol>
          <VCol
            lg="9"
            xs="12"
          >
            <LibraryNewBooks :search="search" />
          </VCol>
        </VRow>
      </VCard>
    </article>
  </UBanner>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import UBanner from '@components/UBanner.vue'
import documentTitle from '@/mixins/documentTitle'
import LibraryTreeView from '@components/LibraryTreeView.vue'
import LibraryNewBooks from '@components/LibraryNewBooks.vue'
import { pageScroll } from '@/utils/common'

export default {
  name: 'TheLibrary',

  components: {
    LibraryNewBooks,
    LibraryTreeView,
    UBanner,
  },

  mixins: [documentTitle],

  data () {
    return {
      libraryTreeViewShow: true,
      documentTitle: '$sdo.lc_menu_library.one',
      search: '',
    }
  },

  computed: {
    ...mapGetters({
      librarySettings: getters.LIBRARY_SETTINGS,
    }),

    breadCrumbs () {
      return [
        {
          text: this.$store.getters.LIBRARY_SETTINGS.title,
          disabled: true,
        },
      ]
    }
  },

  created () {
    this.getLibrary()
    this.getLibrarySettings()
  },

  updated () {
    this.pageScroll()
  },

  methods: {
    ...mapActions({
      getLibrary: actions.GET_LIBRARY,
      getLibrarySettings: actions.GET_LIBRARY_SETTINGS,
    }),

    pageScroll () {
      pageScroll('.library-catalog__button', this.$vuetify.breakpoint.smAndDown)
    }
  }

}
</script>

<style lang="scss">
.library-new-books .bvi-speech-text,
.library-current-books .bvi-speech-text,
.library-all-books .bvi-speech-text {
  height: 100%;
}

.library-new-books,
.library-documents,
.library-all-books,
.library-current-books {
  height: 100%;
}

.library__row-container .col,
.library-documents__container .col {
  flex-basis: auto;
}

.v-breadcrumbs {
  li {
    max-width: 30%;
    word-break: break-word;
  }
}

.page-title {
  color: var(--v-text-darken4);
}

.page-title:hover {
  text-decoration: none;
  cursor: pointer;
}

.library__buttons-container,
.library__search-container {
  margin-left: 0;
}

.v-application {
  ul {
    padding: 1rem 0.75rem;
  }
}

.library-catalog__button {
  display: none;
}

.library__link,
.library-book__link,
.library-all-books__link,
.library-current-books__link,
.library-new-books__link,
.library-documents__link {
  text-decoration: none !important;
  color: inherit !important;
}

.library-all-books__tag,
.library-current-books__tag,
.library-new-books__tag {
  display: inline-block;
  max-width: 6em;
  font-size: 0.75em;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.5ch 1ch;
  padding: 0.5ch 1ch;
  border-radius: 0.75em;
  background-color: var(--v-background-base);
  border: solid 1px var(--v-text-base);
  color: var(--v-text-base);
}

.library-all-books__img,
.library-all-books__img-default,
.library-current-books__img,
.library-current-books__img-default,
.library-new-books__img,
.library-new-books__img-default,
.library-documents__img,
.library-documents__img-default {
  width: 50%;
  padding: 1rem;
  object-fit: contain;
}

.library-all-books__items,
.library-documents__items,
.library-current-books__items,
.library-new-books__items {
  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-direction: column;
}

.library-all-books__card,
.library-current-books__card {
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 19.5rem;
  transition: linear 0.1s;
}

.library-all-books__card:hover,
.library-new-books__card:hover,
.library-current-books__card:hover,
.library-documents__card:hover {
  opacity: 0.8;
}

.library-all-books__card-title,
.library-current-books__card-title,
.library-new-books__card-title,
.library-documents__card-title {
  display: block;
}

.library-all-books__img-group,
.library-new-books__img-group,
.library-current-books__img-group,
.library-documents__img-group {
  display: contents;
}

.library-all-books__tags,
.library-current-books__tags,
.library-new-books__tags {
  padding: 0.625rem !important;
}

.library-all-books__title,
.library-current-books__title,
.library-book__title,
.library-new-books__title,
.library-documents__title {
  margin-bottom: 1rem;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .v-breadcrumbs {
    li {
      max-width: 100% !important;
      word-break: break-word;
    }
  }

  .library-catalog__button {
    display: inline;
    margin-right: 1rem;
  }

  .library__tree-view {
    display: none;
  }

  .library__row-container .container {
    max-width: inherit;
  }
}
</style>
