<template>
  <div class="container">
    <h2 class="library-all-books__title">
      {{ $t('$app.library_all_materials') + ':' }}
    </h2>
    <VRow v-if="extendedSearchLibrary">
      <template v-for="search in extendedSearchLibrary">
        <VCol
          :key="search.id"
          xl="4"
          sm="6"
          xs="12"
        >
          <RouterLink
            class="library-all-books__link"
            :to="`/library/book/${search.id}`"
          >
            <div class="library-all-books bvi-speech">
              <VCard class="library-all-books__card">
                <div class="library-all-books__img-group">
                  <img
                    v-if="search.cover"
                    class="library-all-books__img"
                    :src="`/${ search.cover }`"
                    alt="img"
                  >
                  <img
                    v-else
                    class="library-all-books__img-default"
                    src="/assets/images/default-document-cover.svg"
                    alt="img"
                  >
                </div>
                <div class="library-all-books__items">
                  <VCardTitle
                    v-show="search.title"
                    :title="search.title"
                    class="library-all-books__card-title"
                  >
                    {{ shortenedLibraryName (search.title, 60) }}
                  </VCardTitle>
                  <ul class="library-all-books__tags">
                    <li
                      v-for="tag in shortenedLibraryTags (search,3)"
                      :key="tag.id"
                      :title="tag"
                      class="library-all-books__tag"
                    >
                      {{ tag }}
                    </li>
                  </ul>
                  <VCardText v-show="search.author">
                    <strong>{{ $t('$app.library_author') + ':' }} </strong>{{ shortenedLibraryName (search.author, 20) }}
                  </VCardText>
                  <VCardText v-show="search.publication_year">
                    <strong>{{ $t('$app.library_publication_year') + ':' }} </strong>{{ search.publication_year }}
                  </VCardText>
                </div>
              </VCard>
            </div>
          </RouterLink>
        </VCol>
      </template>
    </VRow>
    <UPlaceholder v-else />
  </div>
</template>

<script>
import UPlaceholder from '@components/UPlaceholder.vue'
import { shortenedString, shortenedArray } from '@/utils/common'

export default {
  name: 'LibraryallBooks',
  components: {
    UPlaceholder
  },

  props: {
    extendedSearchLibrary: {
      type: Array,
      default: () => []
    },
  },

  methods: {
    shortenedLibraryName (search, maxLength) {
      return shortenedString(search, maxLength)
    },

    shortenedLibraryTags (item, maxLength) {
      return shortenedArray(item, maxLength)
    }
  }
}
</script>

<style lang="scss">

</style>
