<template>
  <div class="container">
    <h2 class="library-book__title">
      {{ $t('$app.library_information') + ':' }}
    </h2>
    <div>
      <div class="library-book library-book__container bvi-speech">
        <div class="library-book__img-group">
          <img
            v-if="book.cover"
            class="library-book__img"
            :src="`/${ book.cover }`"
            alt="img"
          >
          <img
            v-else
            class="library-book__default-img"
            src="/assets/images/default-document-cover.svg"
            alt="img"
          >
        </div>
        <div class="library-book__item-group">
          <h2 class="library-book__sub-title">
            {{ book.title }}
          </h2>
          <p
            v-for="(item, index) in books"
            :key="index"
          >
            <strong v-if="item.name">{{ item.title }}: </strong>{{ item.name }}
          </p>
          <ul class="library-book__tags">
            <li
              v-for="tag in book.tags"
              :key="tag"
              class="library-book__tag"
            >
              <RouterLink
                class="library-book__link"
                :to="{ name: 'documents', params: { tag: tag }}"
              >
                {{ shortenedLibraryTag (tag) }}
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
      <div class="library-book__tab-container">
        <VTabs
          v-model="tab"
          color="primary"
          grow
        >
          <VTab
            :key="0"
            :disabled="!book.body"
          >
            {{ $t('$app.library_description') }}
          </VTab>
          <VTab
            :key="1"
            :disabled="!book.comments"
          >
            {{ $t('$app.library_comment') }}
          </VTab>
          <VTab
            :key="2"
            :disabled="!book.files.length"
          >
            {{ $t('$app.library_files') }}
          </VTab>
        </VTabs>
      </div>
      <div class="library-book__tab-content library-book__tab-content-container">
        <VTabsItems v-model="tab">
          <VTabItem
            :key="0"
            class="bvi-speech"
          >
            <div v-html="book.body" />
          </VTabItem>
          <VTabItem
            :key="1"
            class="bvi-speech"
          >
            <div v-html="book.comments" />
          </VTabItem>
          <VTabItem
            :key="2"
          >
            <div class="bvi-speech">
              <div
                v-if="agreement"
                class="library-book__agreement"
              >
                <p>
                  <strong v-html="$t('$app.library_notification')" />
                </p>
                <ul>
                  <li>{{ $t('$app.library_forbidden_to_copy') }}</li>
                  <li>{{ $t('$app.library_forbidden_to_transfer') }}</li>
                </ul>
                <VBtn
                  class="library-book__button"
                  color="primary"
                  @click="agreement=false"
                >
                  {{ $t('$app.library_accept') }}
                </VBtn>
              </div>
              <div
                v-else
                class="library-book__files"
              >
                <ul
                  v-for="file in book.files"
                  :key="file.id"
                  class="library-book__tab"
                >
                  <li>
                    <a
                      :href="`/${file.uri}`"
                      target="_blank"
                    >{{ file.title }}</a>
                    <div><label>{{ file.mime_type }} {{ formatSize(file.size) }}</label></div>
                  </li>
                </ul>
              </div>
            </div>
          </VTabItem>
        </VTabsItems>
      </div>
    </div>
  </div>
</template>

<script>
import { formatSize } from '@/utils/common'

export default {
  name: 'LibraryBook',

  props: {
    book: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      tab: 0,
      agreement: true,
    }
  },

  computed: {
    books () {
      return [
        {
          title: 'Автор',
          name: this.book.author,
        },
        {
          title: 'ISBN',
          name: this.book.isbn,
        },
        {
          title: 'УДК',
          name: this.book.udk,
        },
        {
          title: 'Год',
          name: this.book.publication_year,
        },
        {
          title: 'Место издания',
          name: this.book.publication_location,
        },
        {
          title: 'Издательство',
          name: this.book.publication_office,
        },
      ]
    }
  },

  methods: {
    formatSize,
    shortenedLibraryTag (tag) {
      if (tag.length > 40) {
        return tag.slice(0, 40) + '...'
      } else {
        return tag
      }
    },
  }
}
</script>

<style lang="scss">
.library-book__container {
  display: flex;
  margin: 0 -0.75rem;
  padding-right: 1rem;
  word-break: break-word;
}

.library-book__sub-title {
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1;
}

.library-book__item-group {
  width: 100%;
}

.library-book__img-group {
  margin-right: 1em;
}

.library-book__img {
  margin: 0 0.75rem;
  width: auto;
  height: auto;
  max-width: 360px;
  max-height: 360px
}

.library-book__tag {
  display: inline-block;
  font-size: 0.75em;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  margin: 0.5ch 0.5ch 0.5ch 0;
  padding: 0.5ch 1ch;
  border-radius: 0.75em;
  background-color: var(--v-background-base);
  border: solid 1px var(--v-text-base);
  color: var(--v-text-base);
}

.library-book__tab-container {
  margin-top: 1.25rem;
  display: block;
  width: 50%;
}
.library-book__tab-content-container {
  padding: 1rem;
  width: 100%;
}

.library-book__button {
  margin: 0.75rem 0;
}

.library-book__tab {
  padding: 0 0 0 1.5rem !important;
}

.v-application {
  .library-book__tags {
    padding: 0;
  }
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0,0,0,.74);
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .library-book__container {
    display: flex;
    align-items: center;
  }

  .library-book__item-group {
    padding: 1rem;
  }

  .library-book__img-group {
   display: contents;
  }

  .library-book__default-img {
    width: 50%;
  }

  .library-book__img {
    width: 50%;
  }
  .v-slide-group__prev {
    display: none !important;
  }

  .library-book__tab-content-container {
    margin-bottom: 1rem;
  }

}

@media (max-width: 725px) {
  .library-book__tab-container {
    width: 100%;
    margin-top: 0;
  }
}

@media (max-width: 414px) {
  .library-book__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .library-book__tab-container {
    width: 100%;
    margin-top: 0;
  }
}
</style>
