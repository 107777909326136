<template>
  <div class="container">
    <h2 class="library-current-books__title">
      {{ $t('$app.library_materials') + ':' }}
    </h2>
    <VRow v-if="filteredCurrentLibrary?.length">
      <VCol
        v-for="item in filteredCurrentLibrary"
        :key="item.id"
        xl="4"
        sm="6"
        xs="12"
      >
        <RouterLink
          class="library-current-books__link"
          :to="`/library/book/${item.id}`"
        >
          <div class="library-current-books bvi-speech">
            <VCard class="library-current-books__card">
              <div class="library-current-books__img-group">
                <img
                  v-if="item.cover"
                  class="library-current-books__img"
                  :src="`/${ item.cover }`"
                  alt="img"
                >
                <img
                  v-else
                  class="library-current-books__img-default"
                  src="/assets/images/default-document-cover.svg"
                  alt="img"
                >
              </div>
              <div class="library-current-books__items">
                <VCardTitle
                  v-show="item.title"
                  :title="item.title"
                  class="library-current-books__card-title"
                >
                  {{ shortenedLibraryName (item.title, 60) }}
                </VCardTitle>
                <ul class="library-current-books__tags">
                  <li
                    v-for="tag in shortenedLibraryTags (item)"
                    :key="tag.id"
                    :title="tag"
                    class="library-current-books__tag"
                  >
                    {{ tag }}
                  </li>
                </ul>
                <VCardText v-show="item.author">
                  <strong>{{ $t('$app.library_author') + ':' }} </strong>{{ shortenedLibraryName (item.author, 20) }}
                </VCardText>
                <VCardText v-show="item.publication_year">
                  <strong>{{ $t('$app.library_publication_year') + ':' }} </strong>{{ item.publication_year }}
                </VCardText>
              </div>
            </VCard>
          </div>
        </RouterLink>
      </VCol>
    </VRow>
    <UPlaceholder v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as getters from '@/store/getters/types'
import * as actions from '@/store/actions/types'
import UPlaceholder from '@components/UPlaceholder.vue'
import { shortenedString } from '@/utils/common'

export default {
  name: 'LibraryCurrentBooks',
  components: {
    UPlaceholder
  },

  props: {
    search: {
      type: String,
      default: '',
    }
  },

  computed: {
    ...mapGetters({
      library: getters.LIBRARY,
      libraryCurrentCatalog: getters.LIBRARY_CURRENT_CATALOG,
    }),

    filteredCurrentLibrary () {
      if (this.search) {
        const search = this.search.toUpperCase()
        return this.libraryCurrentCatalog?.filter((document) => {
          const titleMatch = document.title.toUpperCase().includes(search)
          const authorMatch = document.author.toUpperCase().includes(search)
          const yearMatch = document.publication_year.toUpperCase().includes(search)
          const tagsMatch = (document.tags ?? []).filter((tag) => {
            return tag.toUpperCase().includes(search)
          }).length > 0

          return titleMatch || authorMatch || tagsMatch || yearMatch
        })
      }
      return this.libraryCurrentCatalog
    }
  },

  watch: {
    $route () {
      this.getLibraryCurrentCatalog(this.$route.params.id)
    }
  },

  created () {
    this.getLibraryCurrentCatalog(this.$route.params.id)
  },

  methods: {
    ...mapActions({
      getLibraryCurrentCatalog: actions.GET_LIBRARY_CURRENT_CATALOG
    }),

    shortenedLibraryName (search, maxLength) {
      return shortenedString(search, maxLength)
    },

    shortenedLibraryTags (item) {
      if (item.tags_count > 3) {
        return item.tags.slice(0, 3)
      } else {
        return item.tags
      }
    }
  }
}
</script>

<style lang="scss">

</style>
